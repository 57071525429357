const basePageFields: string = `
        id
        path
        title
        status
        contentType: _collection
        slug
        locale
        publishedAt
        translations
  `;

export default basePageFields;